import React from 'react';
import '../linkcard/style.css';

export const OuterLinkCard = ({
  image_src,
  image_alt,
  title,
  subtext,
  link
}) => {
  return (
    <a className="linkcard_container" href={link}>
      <img 
        src={image_src}
        alt={image_alt}
      />
      <div className="linkcard_title">
        {title}
      </div>
      <div className="linkcard_subtext">
        {subtext}
      </div>
    </a>
  );
}

export default OuterLinkCard;
