import React from 'react';
import './style.css';

export const Instructions = () => {
  return (
    <div className="instructions_container">
      <h2>
        Platts Market Data
      </h2>
      <div className="instructions_explained">
        Export Excel formatted data by specifying company market symbols, contract months, and market dates
      </div>
      <div className="instructions_required">
        *Symbol is required
      </div>
    </div>
  );
}

export default Instructions;
