import React from 'react';
import './style.css';


export const DownloadLink = ({
  info
}) => {

  var text = window.URL.createObjectURL(info.url);
  
  return (
    <div className="submit_container">
      <a 
        href={text}
        download={`${info.key}.csv`}
        target='_blank'
        rel="noopener noreferrer"
        className="submit_button sheet_button"
      >
        {info.key}
      </a>
    </div>
  );
}

export default DownloadLink;
