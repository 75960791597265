import React from 'react';
import { 
  OuterLinkCard 
} from '../../components/outerlinkcard';
import '../../components/instructions/style.css';
import './style.css';

export const NotLoggedIn = () =>(
  <div className="App">
    <div className="NotLoggedIn_blank">

    </div>
    <div className="linkcardgroup_container">
      <OuterLinkCard
        image_src="/img/office-pic.jpeg"
        image_alt="office image"
        title="Login"
        subtext="Access the market data by logging in here"
        link="/login"
      />
    </div>
  </div>
);

export default NotLoggedIn;
