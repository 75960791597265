export async function doAjax(url, json =true) {
  var header = {
    'Content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8;charset=ISO-8859-1'};
  const results = await fetch(url, { header})
    .then(function(response) {
      if (!json) {
        return response.blob();
      }
      return response.json();
    })
    .then(function(results) {
      return results;
    })
    .catch(function(error) {
      console.error(error);
      return {'message': '', 'status':'failed'};
    });
  return results;
}

export default doAjax;