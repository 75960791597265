import React from 'react';
import './style.css';
import {
  Link
} from "react-router-dom";

export const LinkCard = ({
  image_src,
  image_alt,
  title,
  subtext,
  link
}) => {
  return (
    <Link className="linkcard_container" to={link}>
      <img 
        src={image_src}
        alt={image_alt}
      />
      <div className="linkcard_title">
        {title}
      </div>
      <div className="linkcard_subtext">
        {subtext}
      </div>
    </Link>
  );
}

export default LinkCard;
