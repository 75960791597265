import React from 'react';
import '../instructions/style.css';

export const InstructionsHomePage = () => {
  return (
    <div className="instructions_container">
      <h2>
        Best Practice Energy's Market Watch Data
      </h2>
      <div className="instructions_explained">
      View and Download data from our Market Data source
      </div>
      <div className="instructions_required">
      </div>
    </div>
  );
}

export default InstructionsHomePage;
