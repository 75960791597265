import React, { Component } from 'react';
import { 
  Instructions 
} from '../../components/instructions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import './style.css';
import { doAjax } from '../../helpers';
import { Loading } from '../../components/loading';
import { DownloadLink } from '../../components/downloadLink';
import { symbols } from './platts_symbols';

export class PlattsData extends Component {
  constructor() {
    super();
    const today = new Date()
    const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate()-1)
    //const lastMonth = new Date(today.getFullYear(), today.getMonth()-1, today.getDate())
      this.state = { 
      symbol: '',
      contract_date_start: '10/01/2018',
      contract_date_end: '10/01/2030',
      market_date_start: '10/25/2018',
      market_date_end: this.formatDate(yesterday),
      data: [],
      loading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.ChangeSymbol = this.ChangeSymbol.bind(this);
  }

  adjustDateName(date = "") {
    if (date.length > 9) {
      return `${date.substring(0, 2)}/${date.substring(8, 10)}`
    }
    return "";
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  
  formatDate(date) {
    return [
      this.padTo2Digits(date.getMonth() + 1),
      this.padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join('/');
  }

  async handleSubmit() {
    const {
      symbol,
      contract_date_start,
      contract_date_end,
      market_date_start,
      market_date_end,
    } = this.state;
    this.setState({
      loading: true,
    })
    const url = `https://bpe-consulting-tools.azurewebsites.net/api/MarketDataXLApi?symbol=${symbol}&contract_date_start=${contract_date_start}&contract_date_end=${contract_date_end}&market_date_start=${market_date_start}&market_date_end=${market_date_end}&limit=10000000`;

    var data = await doAjax(url, false);
    var key = `${symbol} c_${this.adjustDateName(contract_date_start)}:${this.adjustDateName(contract_date_end)} m_${this.adjustDateName(market_date_start)}:${this.adjustDateName(market_date_end)}`;
    this.setState({
      loading: false,
      data: [{
        key: `${key}`,
        url: data,
      }, ...this.state.data,]
    });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(data);
    link.download = `${key}.csv`;
    link.click();

    // window.open(data, "_blank");
  }

  myChangeHandler = (event) => {
    let value = event.target.value
    if (value.includes("-")) {
      var date = value.split("-");
      if (date !== undefined && date.length === 3) {
        value = `${date[1]}/${date[2]}/${date[0]}`;
      }
    }
    this.setState({
      [event.target.name]: value
    });
  }

  applyDate = (key, value) => {
    var date = `${value.getMonth()+1}/1/${value.getFullYear()}`;
    this.setState({
      [key]: date
    });
  }
  applyFullDate = (key, value) => {
    var date = `${value.getMonth()+1}/${value.getDate()}/${value.getFullYear()}`;
    this.setState({
      [key]: date
    });
  }

  ChangeSymbol = (key, value) => {
    if (value != null) {
      this.setState({
        [key]: value.key
      });
    } else {
      this.setState({
        [key]: ''
      });
    }
  }

  render() {
    const {
      symbol,
      contract_date_start,
      contract_date_end,
      market_date_start,
      market_date_end,
      loading,
      data,
    } = this.state;

    return (
      <div className="App">
        <Instructions/>
        <div>
          <div className="form_group_container">
            <h3>
              Symbol
            </h3>
            <Autocomplete
              className="symbols_input"
              // multiple
              limitTags={1}
              id="multiple-limit-tags"
              options={symbols}
              getOptionLabel={(option) => option.text}
              onChange={(event, value) => {
                this.ChangeSymbol("symbol",value)
              }}
              renderInput={(params) => (
                <TextField {...params} 
                  variant="outlined" 
                  label="Symbols" 
                  placeholder="Enter Symbols here" 
                />
              )}

            />
            <h3> 
              Contract Months 
            </h3>
            <DatePicker
              openTo="year"
              views={["year", "month"]}
              label="Start Date"
              value={contract_date_start}
              onChange={val => {
                this.applyDate("contract_date_start", val._d)
              }}
            />
            <DatePicker
              openTo="year"
              views={["year", "month"]}
              label="End Date"
              value={contract_date_end}
              onChange={val => {
                this.applyDate("contract_date_end", val._d)
              }}
            />
            <h3> 
              Market Dates
            </h3>
            <DatePicker
              autoOk
              format="MM/DD/yyyy"
              label="Start Date"
              minDate={new Date("October 25, 2018 00:00:00")}
              value={market_date_start}
              onChange={val => {
                this.applyFullDate("market_date_start", val._d)
              }}
            />
            <DatePicker
              autoOk
              format="MM/DD/yyyy"
              label="End Date"
              value={market_date_end}
              onChange={val => {
                this.applyFullDate("market_date_end", val._d)
              }}
            />
            {
              loading &&
              <Loading/>
            }
            {
              symbol &&
              !loading &&
              <div className="submit_container">
                <button 
                  onClick={this.handleSubmit}
                  className="submit_button"
                >
                  Submit
                </button>
              </div>
            }
            {
              !symbol &&
              !loading &&
              <div className="submit_container">
                <button 
                  className="not_submit_button"
                >
                  Submit
                </button>
              </div>
            }
            {
              data.length > 0 &&
              <h3> 
                History
              </h3>
            }
            {
              data.length > 0 &&
              data.map(info =>
                <DownloadLink
                  info={info}
                />
              )
            }
          </div>
          
        </div>
      </div>
    );
  }
}

export default PlattsData;
