import React, { Component } from 'react';
import {
  Link
} from "react-router-dom";
import './style.css';

export class Navigation extends Component {
  constructor() {
    super()
    this.state = {
      active: false,
      nav_class: 'nav_symbol',
      nav_links: 'hide_nav_links',
    }
    this.toggle = this.toggle.bind(this)
    this.exit = this.exit.bind(this)
  }

  exit() {
    this.setState(state => ({ 
      nav_class: 'nav_symbol',
      nav_links: 'hide_nav_links'
    }));
  }

  toggle (e) {
    console.log('e');
    console.log(this.state.nav_class);
    if (this.state.nav_class === 'nav_symbol') {
      this.setState(state => ({ 
        nav_class: 'nav_close',
        nav_links: 'show_nav_links'
      }))
    } else {
      this.setState(state => ({ 
        nav_class: 'nav_symbol',
        nav_links: 'hide_nav_links'
      }))
    }
    this.setState(state => ({ 
      active: !state.active
    }))
  }

  render () {
    return (
      <nav>
        <div className={`${this.state.nav_class}`} onClick={this.toggle}>
          <div className='bar1' />
          <div className='bar2' />
          <div className='bar3' />
        </div>
        <ul className={`${this.state.nav_links}`}>
          <li>
            <Link to="/">
              Home
            </Link>
          </li>
          <li>
            <Link to="/plattsdata">
              Platts Data
            </Link>
          </li>
          <li>
            <a href="/logout">
              Logout
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navigation;
