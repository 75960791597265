import React, { Component } from 'react';
import { 
  InstructionsHomePage 
} from '../../components/instructions_main';
import { 
  LinkCard 
} from '../../components/linkcard';
import './style.css';

export class HomePage extends Component {

  render() {
    return (
      <div className="App">
        <InstructionsHomePage/>
        <div className="linkcardgroup_container">
          <LinkCard
            image_src="/img/market_data_banner.jpg"
            image_alt="market data banner"
            title="Platts Data"
            subtext="Plug in dates and market symbols to export data "
            link="/plattsdata"
            linktext="View Market Data"
          />
        </div>
      </div>
    );
  }
}

export default HomePage;
